import en from "/locales/en-US.yml";
import ca from "/locales/ca-ES.yml";
import es from "/locales/es-ES.yml";

export default defineI18nConfig(() => {
  return {
    legacy: true,
    locale: 'en',
    messages: {
      en,
      ca,
      es
    }
  }
})
